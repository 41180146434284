/* Variables Definitions */
/* Using modern `hsla` function by default */

/* Common variables */
:root {
  --color-background: 0 0% 100%;
  --color-background-vivid: 0 0% 100%;
  --color-background-dull: 0 0% 90%;
  --color-foreground: 0 0% 10%;
  --color-foreground-vivid: 0 0% 10%;
  --color-foreground-dull: 0 10% 10%;
  --color-border: 0 0% 85%;
  --color-primary: 224 80% 50%;
  --color-primary-vivid: 224 90% 45%;
  --color-primary-dull: 224 80% 45%;
  --color-primary-foreground: 240 50% 95%;
  --color-primary-foreground-vivid: 240 50% 100%;
  --color-primary-foreground-dull: 240 40% 100%;
  --color-secondary: 0 0% 85%;
  --color-secondary-vivid: 0 0% 80%;
  --color-secondary-dull: 0 0% 90%;
  --color-secondary-foreground: 0 0% 20%;
  --color-secondary-foreground-vivid: 0 0% 0%;
  --color-secondary-foreground-dull: 0 0% 90%;
  --color-danger: 0 70% 50%;
  --color-danger-vivid: 0 80% 50%;
  --color-danger-dull: 0 70% 50%;
  --color-danger-foreground: 0 80% 95%;
  --color-danger-foreground-vivid: 0 80% 100%;
  --color-danger-foreground-dull: 0 70% 100%;
  --color-safe: 160 84% 40%;
  --color-safe-vivid: 160 84% 50%;
  --color-safe-dull: 160 84% 30%;
  --color-safe-foreground: 160 84% 40%;
  --color-safe-foreground-vivid: 160 84% 40%;
  --color-safe-foreground-dull: 160 84% 40%;
}

/* Variables only for light theme */
.light {
}

/* Variables only for dark theme */
.dark {
  --color-background: 212 35% 11%;
  --color-background-vivid: 212 35% 11%;
  --color-background-dull: 212 35% 20%;
  --color-foreground: 180 20% 97%;
  --color-foreground-vivid: 180 20% 100%;
  --color-foreground-dull: 180 20% 90%;
  --color-border: 212 35% 20%;
  --color-secondary: 212 35% 25%;
  --color-secondary-vivid: 212 35% 30%;
  --color-secondary-dull: 212 35% 25%;
  --color-secondary-foreground: 212 35% 85%;
  --color-secondary-foreground-vivid: 212 35% 100%;
  --color-secondary-foreground-dull: 212 35% 70%;
}
