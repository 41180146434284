.rich_text ul,
.rich_text ol {
  @apply pl-4;
}

.rich_text ul {
  @apply list-disc;
}

.rich_text ol {
  @apply list-decimal;
}
