@import 'app/variable.scss';
@import 'app/common.scss';
@import 'app/markdown.scss';
@import 'component/index.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-size: 17px;
}

:root,
[lang^='en'] {
  font-family:
    system-ui,
    -apple-system,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Arial,
    Noto Sans,
    Liberation Sans,
    sans-serif,
    Apple Color Emoji,
    Segoe UI Emoji,
    Segoe UI Symbol,
    Noto Color Emoji;
}

[lang^='zh'],
[lang^='ko'],
[lang^='ja'] {
  font-family:
    Source Han Sans SC,
    PingFang SC,
    Microsoft YaHei,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    Helvetica Neue,
    Noto Sans CJK SC,
    WenQuanYi Micro Hei,
    sans-serif;
}

a {
  @apply underline underline-offset-[.2em];
}

a[target='_blank']:not(.no_decorator) {
  @apply after:content-['↗'] after:align-super after:text-[70%] after:text-foreground/60;
}

:focus {
  @apply outline outline-offset-2 outline-2 outline-primary/60;
}

button a {
  @apply no-underline;
}

:target::before {
  /* make id link (#) leave some space on top after clicking */
  content: '';
  display: block;
  height: 65px;
  margin-top: -65px;
  visibility: hidden;
}

p {
  @apply my-2;
}

h1,
h2,
h3,
h4,
h5,
h6,
.title {
  @apply capitalize;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply my-0 leading-[1em];
}

h1 {
  @apply text-3xl font-black leading-[1em];
}

h2 {
  @apply text-2xl font-extrabold leading-[1em];
}

h3 {
  @apply text-xl font-bold leading-[1em];
}

h4,
h5,
h6 {
  @apply font-semibold;
}

table {
  @apply w-full;
}

select {
  @apply border-black_alpha_200 bg-transparent;
}

.ctn {
  @apply container mx-auto max-w-[1240px] px-2 md:px-2;
}

.cap:first-letter {
  text-transform: uppercase;
}

.case_initial {
  text-transform: initial;
}

.nav_item {
  @apply r gap-1 items-center rounded capitalize no-underline;
}

.nav_item.active {
  @apply bg-foreground/10;
}

.help,
.comment {
  @apply font-normal text-foreground/60;
}

.help {
  @apply text-sm;
}

.trivial {
  @apply text-xs;
}

.text_dim {
  @apply text-foreground/50;
}

.text_cut {
  @apply inline-block overflow-hidden text-ellipsis whitespace-nowrap align-text-bottom;
}

.text_scroll {
  @apply overflow-auto whitespace-nowrap;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.c {
  @apply flex flex-col;
}

.r {
  @apply flex flex-row;
}

.title {
  font-size: 110%;
  font-weight: bold;
}

.tile {
  @apply rounded bg-foreground/5 p-2;
}

.prose {
  max-width: initial;
}

.btn_base {
  @apply px-5 py-3 text-base;
}
