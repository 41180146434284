.rich_text p {
  @apply my-3;
}

.rich_text.compact p {
  &:first-of-type,
  &:last-of-type {
    @apply my-0;
  }
}
