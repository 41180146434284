.suggestion_list {
  @apply relative overflow-hidden rounded border border-foreground/5 bg-background_dull text-foreground shadow;
}

.suggestion_item {
  @apply w-full cursor-pointer p-2 hover:bg-background;
}

.suggestion_item.active {
  @apply bg-background;
}
