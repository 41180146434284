a.btn {
  @apply after:content-none;
}

.btn {
  @apply inline-flex content-center items-center justify-center justify-items-center gap-[.5em] rounded text-center font-medium capitalize transition-colors focus:outline-none focus:ring-4 focus:ring-primary/50 disabled:cursor-not-allowed disabled:opacity-60 whitespace-nowrap;

  :disabled {
    @apply grayscale-[60%];
  }

  svg {
    @apply h-[1.1em] text-[1.1em] leading-none;
  }
}

.btn_group {
  @apply inline-flex flex-wrap gap-0;

  .btn {
    @apply rounded-none border-l border-t border-b transition-colors;
    &:first-of-type {
      @apply rounded-bl rounded-tl;
    }

    &:last-of-type {
      @apply rounded-br rounded-tr border-r;
    }
  }
}

.btn_solid_primary,
[type='button'].btn_solid_primary,
[type='reset'].btn_solid_primary,
[type='submit'].btn_solid_primary {
  @apply bg-primary text-primary_foreground hover:bg-primary_vivid hover:text-primary_foreground_vivid;
}

.btn_solid_danger,
[type='button'].btn_solid_danger,
[type='reset'].btn_solid_danger,
[type='submit'].btn_solid_danger {
  @apply bg-danger text-danger_foreground hover:bg-danger_vivid hover:text-danger_foreground_vivid;
}

.btn_solid_risky,
[type='button'].btn_solid_risky,
[type='reset'].btn_solid_risky,
[type='submit'].btn_solid_risky {
  @apply bg-secondary text-secondary_foreground hover:bg-danger_vivid hover:text-danger_foreground_vivid;
}

.btn_solid_safe,
[type='button'].btn_solid_safe,
[type='reset'].btn_solid_safe,
[type='submit'].btn_solid_safe {
  @apply bg-safe text-safe_foreground hover:bg-safe_vivid hover:text-safe_foreground_vivid;
}

.btn_solid_secondary,
[type='button'].btn_solid_secondary,
[type='reset'].btn_solid_secondary,
[type='submit'].btn_solid_secondary {
  @apply bg-secondary text-secondary_foreground hover:bg-secondary_vivid hover:text-secondary_foreground_vivid;
}

.btn_solid_light,
[type='button'].btn_solid_light,
[type='reset'].btn_solid_light,
[type='submit'].btn_solid_light {
  @apply bg-foreground/10 text-foreground hover:bg-foreground_vivid/10 hover:text-foreground_vivid;
}

.btn_text_primary,
[type='button'].btn_text_primary,
[type='reset'].btn_text_primary,
[type='submit'].btn_text_primary {
  @apply text-primary hover:text-primary_vivid;
}

.btn_text_secondary,
[type='button'].btn_text_secondary,
[type='reset'].btn_text_secondary,
[type='submit'].btn_text_secondary {
  @apply text-secondary_foreground/90 hover:text-secondary_foreground/100;
}

.btn_text_danger,
[type='button'].btn_text_danger,
[type='reset'].btn_text_danger,
[type='submit'].btn_text_danger {
  @apply text-danger hover:text-danger_vivid;
}

.btn_text_risky,
[type='button'].btn_text_risky,
[type='reset'].btn_text_risky,
[type='submit'].btn_text_risky {
  @apply text-secondary_foreground hover:text-danger_vivid;
}

.btn_text_safe,
[type='button'].btn_text_safe,
[type='reset'].btn_text_safe,
[type='submit'].btn_text_safe {
  @apply text-safe hover:text-safe_vivid;
}

.btn_xs {
  @apply px-3 py-1 text-xs min-h-[2.2em];
  & > * {
    @apply scale-75;
  }
}

.btn_sm {
  @apply px-4 py-2 text-sm min-h-[2.2em];
  & > * {
    @apply scale-90;
  }
}

.btn_lg {
  @apply px-6 py-3 text-lg min-h-[2.2em];
  & > * {
    @apply scale-110;
  }
}

.btn_xl {
  @apply px-7 py-4 text-xl min-h-[2.2em];
  & > * {
    @apply scale-125;
  }
}

.btn_base {
  @apply px-5 py-2 text-base;
}

.btn_action,
[type='button'].btn_action,
[type='reset'].btn_action,
[type='submit'].btn_action {
  @apply bg-transparent p-1 leading-none hover:bg-foreground/20;
}

.btn_action.btn_active,
[type='button'].btn_action.btn_active,
[type='reset'].btn_action.btn_active,
[type='submit'].btn_action.btn_active {
  @apply bg-foreground/10 shadow-inner shadow-sm shadow-foreground/20;
}
