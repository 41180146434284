@import '@/component/reader/style/rich.scss';
@import 'mention.scss';
@import 'hashtag.scss';

.rich_text p.editor_empty:first-child::before {
  @apply text-foreground/30;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
