.swiper_thumb_list {
  .swiper-slide {
    opacity: 0.5;
  }

  .swiper-slide-thumb-active {
    opacity: 1;
  }
}

.swiper {
  .swiper-button-prev,
  .swiper-button-next {
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
      @apply md:opacity-100;
    }
  }

  .swiper-wrapper {
    @apply r items-center;
  }
}

.swiper_thumb_list {
  .swiper-slide {
    @apply aspect-square overflow-hidden flex items-center justify-center bg-foreground/10;
  }
}
