.rich_text pre {
  @apply my-3 rounded bg-black_alpha_900 p-2 text-white/90;
  font-family: 'JetBrainsMono', monospace;
}

.rich_text code {
  @apply bg-none p-0 text-inherit;
}

.rich_text .hljs-comment,
.rich_text .hljs-quote {
  color: #616161;
}

.rich_text .hljs-variable,
.rich_text .hljs-template-variable,
.rich_text .hljs-attribute,
.rich_text .hljs-tag,
.rich_text .hljs-name,
.rich_text .hljs-regexp,
.rich_text .hljs-link,
.rich_text .hljs-name,
.rich_text .hljs-selector-id,
.rich_text .hljs-selector-class {
  color: #f98181;
}

.rich_text .hljs-number,
.rich_text .hljs-meta,
.rich_text .hljs-built_in,
.rich_text .hljs-builtin-name,
.rich_text .hljs-literal,
.rich_text .hljs-type,
.rich_text .hljs-params {
  color: #fbbc88;
}

.rich_text .hljs-string,
.rich_text .hljs-symbol,
.rich_text .hljs-bullet {
  color: #b9f18d;
}

.rich_text .hljs-title,
.rich_text .hljs-section {
  color: #faf594;
}

.rich_text .hljs-keyword,
.rich_text .hljs-selector-tag {
  color: #70cff8;
}

.rich_text .hljs-emphasis {
  font-style: italic;
}

.rich_text .hljs-strong {
  font-weight: 700;
}
