@import 'paragraph.scss';
@import 'blockquote.scss';
@import 'image.scss';
@import 'code.scss';
@import 'list.scss';
@import 'mention.scss';
@import 'hashtag.scss';

.rich_text > *:first-child {
  margin-top: 0;
}

.rich_text > *:last-child {
  margin-bottom: 0;
}

.rich_editor {
  @apply outline-none focus-visible:outline-none;
  img,
  iframe {
    display: block;
    max-width: 100%;
  }
}

.rich_text.compact {
  p {
    margin: 0;
  }
}
